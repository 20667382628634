import React from 'react';
import { Titulek } from '../prvky/Titulek';
import { Link } from 'react-router-dom';

function VysledkyItem({ popis, to }: { popis: string, to: string }) {
    return <Link className="vysledkyItemText" to={to}>{popis}</Link>
}

export function Vysledky() {
    return <section >
        <Titulek nazev="Výsledky" />
        <div className="containerText">
            <p>Výsledky uplynulých ročníků</p>
            <div className="flexboxVysledky">
                <div className="vysledkyItem"> <VysledkyItem to="/vysledky2024" popis="2024" /></div>
                <div className="vysledkyItem"> <VysledkyItem to="/vysledky2023" popis="2023" /></div>
                <div className="vysledkyItem"> <VysledkyItem to="/vysledky2022" popis="2022" /></div>
                <div className="vysledkyItem"> <VysledkyItem to="/vysledky2021" popis="2021" /></div>

            </div>
        </div>


    </section>
}

