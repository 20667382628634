import React from 'react';

export function Upoutavka() {
    return <section className="upoutavka">
        
        <div className="upoutavkaItem">
            <h3>Vyhlášení výsledků</h3>
            <p>9. 9. 2024, Jazz Dock</p>
        </div>
        <div className="upoutavkaItem">
            <div className="button">
                <a href="#terminy">více podrobností</a>
            </div>
        </div>
    </section>
}