import React from 'react';
import { Titulek } from '../prvky/Titulek';

export function ONas() {
    return <section className="onas">
        <Titulek nazev="O nás" />
        <div className="containerText">
            <h3>O soutěži a&nbsp;jejím poslání</h3>
            <p>
                Značky v&nbsp;posledních dekádách silněji vnímají potřebu propojení se
                spotřebiteli na více úrovních, a&nbsp;to skrze stále nové, více či méně
                standardní kanály. Dříve doplňkový BTL marketing se tak postupně stal
                nejdynamičtějším a&nbsp;kreativně nejnáročnějším odvětvím.
            </p>
            <p>
                IMPACT Czech Awards je soutěž, která oceňuje to nejlepší z&nbsp;českého
                integrovaného marketingu, tedy kampaně, které nespoléhají primárně na
                televizi, ale zapojují další formy a&nbsp;prvky přímé komunikace.
            </p>
            <p>
                Dáváme reklamním profesionálům i&nbsp;agenturám prostor porovnat své
                síly mezi sebou. Vítáme práce jak malých, tak velkých týmů. Na čem ale
                záleží, je vyváženost každé kampaně. Hledáme jasnou relevantní
                strategii, svěží kreativní myšlenku, atraktivní profesionální provedení
                a&nbsp;prokazatelné výsledky.
            </p>
        </div>
        <div className="hlavicka" id="hlavickaHistorie">
            <div className="carkovany">
                <div className="levyhorni ctverec" />
                <div className="levydolni ctverec" />
                <div className="horni ctverec" />
                <div className="dolni ctverec" />
                <div className="pravyhorni ctverec" />
                <div className="pravydolni ctverec" />
                <h2>Historie</h2>
            </div>
        </div>
        <div className="containerText">
            <h3>Od historie do současnosti</h3>
            <img className="posledniobr" src="images/prvky/historie.svg" alt="" />
        </div>
    </section>
}