import React from 'react';
import {Titulek} from '../prvky/Titulek';

export function Terminy() {
    return <section className="terminy">
        <div className="proNavigaci proNavigaciTerminy" id="terminy"/>
        <Titulek nazev="Termíny"/>
        <div className="containerText">
            <img className="proDesktop" src="images/prvky/terminy.svg" alt=""/>
            <img className="proMobil" src="images/prvky/terminyMobile.svg" alt=""/>
        </div>
        <div className="proNavigaci" id="poplatky"/>
        <div className="hlavicka" id="hlavickaPoplatky">
            <div className="carkovany">
                <div className="levyhorni ctverec"/>
                <div className="levydolni ctverec"/>
                <div className="horni ctverec"/>
                <div className="dolni ctverec"/>
                <div className="pravyhorni ctverec"/>
                <div className="pravydolni ctverec"/>
                <h2>Poplatky</h2>
            </div>
        </div>
        <div className="containerText">
            <div className="flexboxPorota">
                <div className="flexboxitem">
                    <div className="cenaKruh">
                        <div className="cena">4&nbsp;900&nbsp;Kč</div>
                        <div className="bezDph">(bez DPH)</div>
                    </div>
                    <p className="bold">zvýhodněná cena (early birds)</p>
                </div>
                <div className="flexboxitem">
                    <div className="cenaKruh">
                        <div className="cena">6&nbsp;900&nbsp;Kč</div>
                        <div className="bezDph">(bez DPH)</div>
                    </div>
                    <p className="bold">cena za první standardní přihlášku</p>
                </div>
                <div className="flexboxitem">
                    <div className="cenaKruh">
                        <div className="cena">4&nbsp;900&nbsp;Kč</div>
                        <div className="bezDph">(bez DPH)</div>
                    </div>
                    <p className="bold">
                        cena za druhou
                        <br/>
                        a&nbsp;každou další přihlášku
                    </p>
                </div>
            </div>
            <p>
                Pokud jednu soutěžní práci přihlašujete do více kategorií, je třeba
                poplatek zaplatitjako za více přihlášených prací.
                <br/>
                Ceny jsou uvedeny bez DPH.
            </p>
            <p>
                Při platbě přičtěte k&nbsp;celkové částce 21% DPH.
                <br/>
                Poplatky jsou splatné v&nbsp;termínu uzávěrky přihlášek na účet
                č.&nbsp;19–3190340287/0100. Do zprávy pro příjemce uveďte jméno
                přihlašovatele. Daňový doklad zašleme po přijetí platby.
            </p>
            <p>
                Pokud vaše firemní pravidla umožňují platit až po obdržení faktury,
                napište si o&nbsp;ní v&nbsp;termínu na e-mailovou adresu{' '}
                <a className="oranzovy" href="mailto:info@impactczechawards.cz">
                    info@impactczechawards.cz
                </a>{' '}
                pořadateli soutěže.
            </p>
        </div>
    </section>
}