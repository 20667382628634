import React from 'react';


export function Vysledky2024() {
    return <section className='vysledky'>
        <div className="bezTitulek"></div>
        <div className="container">
            <div className="flexboxVysledky">
                <div className="vysledkyRocnik">2024</div>

            </div>
            <div className="flexboxVysledky">
                <table className='vysledkySpecial'><tr><td>Klient ročníku</td></tr>
                    <tr><td>L‘Oréal Group</td></tr></table>
                <table className='vysledkySpecial'><tr><td>Agentura ročníku</td></tr>
                    <tr><td>TRIAD Advertising</td></tr></table>
            </div>
            <table>
                <tr><td>Název projektu</td><td>Přihlašovatel / spolupracovníci</td><td>Klient</td><td>Kategorie</td><td>Ocenění</td></tr>

                <tr><td>I LOVE DŮCHOD</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td></td><td><span className='oranzovy bold'>GRAND PRIX</span>
                </td></tr>

                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Pivo ze vzduchu konečně spojilo značku Radegast s bojem o vodu</td><td>TRIAD Advertising / Boomerang Communication, Bison & Rose</td><td>Plzeňský Prazdroj</td><td>Budování značky</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Čtyřnohý maskot oprášil lásku Čechů k Seznamu</td><td>TRIAD Advertising / Nice!</td><td>Seznam.cz</td><td>Budování značky</td><td><span className='oranzovy bold'>bronz</span></td></tr>


                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>I LOVE DŮCHOD</td><td>Momentum Czech Republic</td><td>Komerční banka </td><td>Digitální trefa</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>POROBOT</td><td>Good Game / PHD Česká republika, Separation Films, SQUAD.BUZZ</td><td>Komerční banka, Mastercard</td><td>Digitální trefa</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Vodafone Youth Twitch „ad“</td><td>McCann Prague / Good Game</td><td>Vodafone Czech Republic</td><td>Digitální trefa</td><td><span className='oranzovy bold'>bronz</span></td></tr>


                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Národní den bez spěchu 2024</td><td>McCann Prague / Hero & Outlaw</td><td>Česká asociace pojišťoven</td><td>Event, field a sportovní marketing</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Světový den astmatu s AstraZeneca</td><td>SYMBIO Digital / The Chemistry Gallery, BrainZone, Česká iniciativa pro astma</td><td>AstraZeneca Czech Republic</td><td>Event, field a sportovní marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>


                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Měníme osnovy</td><td>Nadace BLÍŽKSOBĚ / Digital First Marketing Group</td><td>Nadace BLÍŽKSOBĚ</td><td>Charitativní a neziskový marketing</td><td><span className='oranzovy bold'>stříbro</span></td></tr>
                
                <tr><td>Revitalizace značky nastartovala změnu vnímání</td><td>WELLEN </td><td>OPU</td><td>Charitativní a neziskový marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>


                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Anti-akné Speedrun</td><td>Good Game</td><td>L‘Oréal Group</td><td>Influencer marketing</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>Tři šampóni</td><td>Good Game</td><td>L‘Oréal Group</td><td>Influencer marketing</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Vodafone Youth Twitch „ad“</td><td>McCann Prague / Good Game</td><td>Vodafone Czech Republic</td><td>Influencer marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Pivo ze vzduchu konečně spojilo značku Radegast s bojem o vodu</td><td>TRIAD Advertising / Boomerang Communication, Bison & Rose</td><td>Plzeňský Prazdroj</td><td>Inovativní myšlenka nebo koncept</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>I LOVE DŮCHOD</td><td>Momentum Czech Republic</td><td>Komerční banka </td><td>Inovativní myšlenka nebo koncept</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>POROBOT</td><td>Good Game / PHD Česká republika, Separation Films, SQUAD.BUZZ</td><td>Komerční banka, Mastercard</td><td>Inovativní myšlenka nebo koncept</td><td><span className='oranzovy bold'>stříbro</span></td></tr>
                <tr><td>Anti-akné Speedrun</td><td>Good Game</td><td>L‘Oréal Group</td><td>Inovativní myšlenka nebo koncept</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Horalky Peanut Butter – Nová dimenze konzervativní značky</td><td>TRIAD Advertising / Marketup, Mindshare</td><td>I.D.C. Holding</td><td>Integrovaná komunikace</td><td><span className='oranzovy bold'>zlato</span></td></tr>
                <tr><td>Dopřejte si zasloužené OREAgování</td><td>TRIAD Advertising</td><td>OREA Hotels & Resorts</td><td>Integrovaná komunikace</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>NGP x Vodafone: Předběhli dobu</td><td>McCann Prague</td><td>Vodafone Czech Republic a Národní galerie Praha</td><td>Nízkorozpočtové kampaně</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Horalky Peanut Butter – Nová dimenze konzervativní značky</td><td>TRIAD Advertising / Marketup, Mindshare</td><td>I.D.C. Holding</td><td>Produktový launch, relaunch nebo trial</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>Anti-akné Speedrun</td><td>Good Game</td><td>L‘Oréal Group</td><td>Produktový launch, relaunch nebo trial</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Čti hru jako nikdy jiný s Flashscore</td><td>TRIAD Advertising</td><td>Livesport</td><td>Produktový launch, relaunch nebo trial</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>POROBOT</td><td>Good Game / PHD Česká republika, Separation Films,SQUAD.BUZZ</td><td>Komerční banka, Mastercard</td><td>Produktový launch, relaunch nebo trial</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Alma Career Content Hub</td><td>Boomerang Communication / Alma Career Czechia</td><td>Alma Career Czechia</td><td>Přímá B2B a B2C komunikace</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>I LOVE DŮCHOD</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td>Sociální média a brandový obsah</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>Tři šampóni</td><td>Good Game</td><td>L‘Oréal Group</td><td>Sociální média a brandový obsah</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Národní den bez spěchu 2024</td><td>McCann Prague / Hero & Outlaw</td><td>Česká asociace pojišťoven</td><td>Veřejný zadavatel</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>150 000 projektů pro lepší Česko</td><td>Momentum Czech Republic</td><td>Ministerstvo pro místní rozvoj</td><td>Veřejný zadavatel</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Nenechte se rozladit – Řitiči</td><td>Agentura GEN</td><td>Platforma VIZE 0</td><td>Veřejný zadavatel</td><td><span className='oranzovy bold'>bronz</span></td></tr>

            </table>

        </div >


    </section >
}