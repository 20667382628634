import React from 'react';
import {Titulek} from '../prvky/Titulek';

export function Galerie() {
    return <section className="galerie">
        <Titulek nazev="Galerie"/>
        <div className="containerText">
            <h3>Zde najdete výsledky a&nbsp;přihlášené projekty.</h3>
        </div>
    </section>
}