import React from 'react';
import {Titulek} from '../prvky/Titulek';

export function Soutez() {
    return <section className="soutez">
        <Titulek nazev="Soutěž"/>
        <div className="containerText">
            <p>
                Poprvé pod novým jménem vyhlašujeme další ročník prestižní domácí
                soutěže, jediné s&nbsp;přímou nominací do evropského finále.
            </p>
            <p>
                V&nbsp;něm v&nbsp;předchozích letech slavili úspěchy mnohé české
                agentury a&nbsp;tvůrci. Letos to můžete být vy.
            </p>
            <p>
                Cesta do Evropy však začíná zde. Prozkoumejte pravidla, kategorie
                a&nbsp;seznamte se s&nbsp;termíny.
                <br/>
                Přihlášky jsou otevřené a&nbsp;early birds netrvá věčně.
            </p>
            <p>Těšíme se na vás!</p>
        </div>
        <img src="images/prvky/Fotka_prechod.jpg" alt=""/>
    </section>
}