import React, { ReactNode, useState } from 'react';
import { Titulek } from '../prvky/Titulek';
import { ReactComponent as SipkaKategorie } from '../images/sipka_kategorie.svg';

// PolozkaKategorie a DefiniceKategorie nejsou komponenty, jen jednoduché JS typy
interface DefiniceKategorie {
    nazev: string
    popis: ReactNode
}

const kategorie: DefiniceKategorie[] = [{
    nazev: 'Integrovaná komunikace',
    popis: <p>
        Kampaně využívající pro efektivní podporu produktu, služby nebo
        společnosti nejméně 3 odlišné typy médií, komunikačních kanálů či
        disciplín, alespoň 2 z&nbsp;nich musí být jiného typu než ATL.
        <br />
        <br />
        Doplňující kritéria: Výběr a&nbsp;integrita zvolených nástrojů
        komunikace
    </p>
},
{
    nazev: 'Přímá B2B a B2C komunikace',
    popis: <p>
        Kampaně pro efektivní podporu produktu, služby nebo společnosti
        cílící na koncového spotřebitele nebo business segment musí primárně
        využívat přímý marketing – tištěný, telemarketing, digitální či
        kombinace.
        <br />
        <br />
        Doplňující kritéria: měřitelná přímá odezva
    </p>
},
{
    nazev: 'Spotřebitelské soutěže a loyalitní programy',
    popis: <p>
        Kampaně pro podporu prodeje nebo rozvoje značky, s&nbsp;cílem zvýšit
        frekvenci/objem nákupu. Využívají motivační odměny krátkodobě
        (spotřebitelské soutěže – týdny, měsíce), tak dlouhodobě (věrnostní
        programy – více než rok).
        <br />
        <br />
        Doplňující kritéria: potenciál udržet pozornost po celou kampaň
    </p>
},
{
    nazev: 'Sponzoring',
    popis: <p>
        Kategorie je určená kampaním, které nejlépe využívají veřejné eventy
        většího rozsahu v&nbsp;oblasti sportu, hudby, kultury, TV vysílání
        nebo turismu ve prospěch značky, produktu nebo celé společnosti.
        <br />
        <br />
        Doplňující kritéria: Relevance sponzoringu vůči značce a&nbsp;cílové
        skupině
    </p>
},
{
    nazev: 'Event, Field a sportovní marketing',
    popis: <p>
        Zážitek v&nbsp;hlavní roli. Eventy, roadshow, ochutnávky, sampling,
        sponzoring nebo podobný typ marketingového turné (hudební, kulturní,
        cirkusové, filmové, sportovní a&nbsp;jiné). <br />
        <br />
        Doplňující kritéria: Relevance vůči značce a&nbsp;cílové skupině
    </p>
},
{
    nazev: 'Instore marketing včetně promo obalů',
    popis: <p>
        Kategorie se zaměřuje na kampaně a&nbsp;aktivity v&nbsp;místě
        prodeje, které pasivním způsobem (např. formou displejů, POS
        materiálů) efektivně ovlivňují zákazníky a&nbsp;motivují je ke
        koupi.
    </p>
},
{
    nazev: 'Retail koncept',
    popis: <p>
        Kategorie oceňuje nové a&nbsp;inovativní formy kamenných prodejen
        (koncepce, aktivity…) z&nbsp;pohledu marketingové komunikace, kdy
        v&nbsp;popředí zájmu již není jen prodej jako takový, ale například
        budování značky, edukace, sociální aspekt či jiné.
    </p>
},
{
    nazev: 'Digitální inovace v retailu',
    popis: <p>
        Kategorie se zaměřuje na nové inovativní (originální) komunikační
        prvky v&nbsp;prostředí in-store. A to jak z&nbsp;pohledu těch, které
        pomáhají zákazníkům lépe, rychleji a&nbsp;efektivněji nakupovat, tak
        současně ty, které navazují na budování značky a&nbsp;pomáhají ji
        v&nbsp;in-store posilovat.
    </p>
},
{
    nazev: 'Produktový launch, relaunch nebo trial',
    popis: <p>
        Kategorie posuzuje kampaně, které co nejefektivněji budují povědomí
        o&nbsp;značce, resp. motivují k vyzkoušení či opakovanému nákupu
        nového, stávajícího nebo modifikovaného produktu, služby či
        společnosti.
    </p>
},
{
    nazev: 'Budování značky',
    popis: <p>
        Kategorie hodnotí kampaně, které zvyšují a&nbsp;vylepšují image,
        posilují hodnoty značky nebo celé společnosti, a&nbsp;využívají
        k&nbsp;tomu všech možných komunikačních prostředků. Minimálně dva
        z&nbsp;nich musí být mimo oblast ATL (TV, OOH, print).
    </p>
},
{
    nazev: 'Veřejný zadavatel',
    popis: <p>
        Projekty zadávané veřejnou správou a&nbsp;neziskovými organizacemi
        (státními a&nbsp;obecními úřady a institucemi, rozpočtovými
        organizacemi a&nbsp;vládními agenturami). Cíle mohou být změna
        veřejného mínění, občanského chování, účasti na veřejných akcích, či
        podpora nákupu regionálních produktů.
    </p>
},
{
    nazev: 'Nízkorozpočtové kampaně',
    popis: <p>
        Projekty s&nbsp;rozpočtem do 500 000, – Kč (na vyžádání organizátora
        potvrdí zadavatel), které splní všechny cíle zadavatele. Práce musí
        zahrnout promo/direct/shopper marketing a&nbsp;musí být klíčové pro
        úspěch kampaně / aktivity.
        <br />
        <br />
        Doplňující kritéria: poměr náklady/výsledek
    </p>
},
{
    nazev: 'Inovativní myšlenka nebo koncept',
    popis: <p>
        Kategorie zkoumá kampaně, které nejlépe využívají jedinečnou
        kreativní nebo marketingovou myšlenku ve vztahu ke konkrétní
        mechanice, komunikačnímu kanálu, typu pobídky nebo přístupu k cílové
        skupině.
    </p>
},
{
    nazev: 'Influencer marketing',
    popis: <p>
        Kategorie je určená pro kampaně, které zásadním způsobem využívají
        práci s&nbsp;obsahem a&nbsp;s jeho tvůrci. Zajímá nás, jakým
        způsobem kampaně využívají postupy content marketingu, práci
        s&nbsp;UGO, blogery a&nbsp;youtubery, a&nbsp;jaký neočekávaný
        zážitek vytvářejí na straně spotřebitele.
    </p>
},
{
    nazev: 'Digitální trefa',
    popis: <p>
        V&nbsp;této kategorii se oceňují kampaně, které nejlépe využívají
        nejrůznějších digitálních kanálů. Důraz je kladen na meřitelný
        výsledek kampané, jejich efektivitu a&nbsp;elevanci zvoleného mixu
        pro definovanou cílovou skupinu.
    </p>
},
{
    nazev: 'Sociální média a brandový obsah',
    popis: <p>
        Kategorie se zaměřuje na projekty využívající sociální média jako
        komunikační kanál pro aktivaci zákazníků. Zajímá nás, jak chytře
        dokážou kampaně využít potenciálu sociálních sítí a&nbsp;médií pro
        komunikaci značek, ať už má jejich obsah jakoukoliv formu.
    </p>
},
{
    nazev: 'Charitativní a neziskový marketing',
    popis: <p>
        Kategorie je určena kampaním s největším pozitivním sociálním přínosem pro charitu, produkt nebo společnost nebo pro bono projektům s cílem pomoci neziskovým subjektům nastavit komunikaci či zvýšit povědomí o předmětu dané charity. Cílem je poukázat na konkrétní problém, ať už sociální, ekonomický nebo politický.
        <br />
        <br />
        Doplňující kritéria: reálná úspěšnost ve prospěch značky nebo služby, měřitelné zviditelnění nebo zvýšení zásahu komunikace
    </p>
},
]

function PolozkaKategorie({ kategorie }: { kategorie: DefiniceKategorie }) {
    const [open, setOpen] = useState(false)
    return <div className={`kategorie ${open ? 'active' : ''}`} onClick={() => setOpen(o => !o)}>
        <div className="kategorieHlavicka ">
            <SipkaKategorie />
            <div className="kategoriePodtrzeni">
                <h3>{kategorie.nazev}</h3>
            </div>
        </div>
        {open &&
            <div className="kategorieText">
                {kategorie.popis}
            </div>}
    </div>
}

export function Kategorie() {
    return <section className="kateg">
        <div className="proNavigaci" id="kategorie" />
        <Titulek nazev="Kategorie" />
        <div className="containerText">
            <p>
                Odborná porota posuzuje přihlašované práce podle 4 kritérií: strategické
                řešení, kreativní řešení, provedení a&nbsp;výsledky.
            </p>
            <p>
                V&nbsp;on-line hodnocení mohou být v&nbsp;rámci každého kritéria
                jednotlivé práce hodnoceny body od 1 do 10 (1 = velmi slabé, 10 =
                vynikající).
            </p>
            <br />
            <p className="bold">
                Získané body mají v&nbsp;jednotlivých kritériích tuto váhu:
            </p>
            <table>
                <tbody>
                    <tr>
                        <td>Strategické řešení</td>
                        <td>25 %</td>
                    </tr>
                    <tr>
                        <td>Kreativní řešení</td>
                        <td>25 %</td>
                    </tr>
                    <tr>
                        <td>Provedení</td>
                        <td>25 %</td>
                    </tr>
                    <tr>
                        <td>Výsledky</td>
                        <td>25 %</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Práce, které v&nbsp;každé kategorii získají nejvyšší počet bodů,
                postoupí na shortlist a&nbsp;ke společnému hodnocení porotou.
            </p>
            <p>
                U některých kategorií jsou v&nbsp;rámci 4 hlavních kritérií nastavena
                také další doplňující měřítka, kterými pomáhají přesněji vyhodnotit
                projekty s&nbsp;ohledem na konkrétní kategorii.
            </p>

            {kategorie.map((k, i) => <PolozkaKategorie key={i} kategorie={k} />)}

            {/* FIXME Ostatní kategorie upravit stejně jako tu první (vloži jako další objekt do pole -kategorie-)*/}









        </div>
    </section>
}