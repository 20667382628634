import React from 'react';
import { Titulek } from '../prvky/Titulek';

export function Porota() {
    return <section className="porota">
        <div className="proNavigaci proNavigaciPorota" id="porota" />
        <Titulek nazev="Porota" />
        <div className="containerText">
            <p>
                Stejně jako naše měřítka hodnocení kampaní jsou založená na rovnováze,
                tak i&nbsp;naše odborná porota se skládá z&nbsp;profesionálů, kteří
                zastupují všechny dílčí disciplíny marketingu.
            </p>
            <div className="flexboxPorota">
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/marketamoreno.jpg" alt="" />
                    <div className="jmeno">Markéta Moreno</div>
                    <p className='bold'>Předsedkyně poroty</p>
                    <p>Markeťačka tělem i duší</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/tomasburka.jpg" alt="" />
                    <div className="jmeno">Tomáš Burka</div>
                    <p>BTL Team Lead</p>
                    <p>Česká spořitelna</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/radanacechova.jpg" alt="" />
                    <div className="jmeno">Radana Čechová</div>
                    <p>Novinářka a šéfredaktorka</p>
                    <p>Marketing & Media</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/matusficko.jpg" alt="" />
                    <div className="jmeno">Matúš Ficko</div>
                    <p>Strategy Planner</p>
                    <p>Triad Advertising</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/robertchmelar.jpg" alt="" />
                    <div className="jmeno">Robert Chmelař</div>
                    <p>Marketing Director</p>
                    <p>Partners</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/miroslavkarlicek.jpg" alt="" />
                    <div className="jmeno">Miroslav Karlíček</div>
                    <p>Vedoucí katedry marketingu</p>
                    <p>VŠE</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/barborakoci.jpg" alt="" />
                    <div className="jmeno">Barbora Kočí</div>
                    <p>Copywriter & Idea Maker</p>
                    <p>Symbio</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/andreakvetenska.jpg" alt="" />
                    <div className="jmeno">Andrea Květenská</div>
                    <p>Event, Retail Comms & BTL Manager</p>
                    <p>Vodafone</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/lindapetrova.jpg" alt="" />
                    <div className="jmeno">Linda Petrová</div>
                    <p>Strategic Planner</p>
                    <p>WELLEN</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/janrysanek.jpg" alt="" />
                    <div className="jmeno">Jan Ryšánek</div>
                    <p>Head of Digital</p>
                    <p>WMC/GREY</p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/alexandrstojcev.jpg" alt="" />
                    <div className="jmeno">Alexandr Stojčev</div>
                    <p>Business and Communication</p>
                    <p></p>
                </div>
                <div className="itemPorota">
                    <img className="fotoramecek" src="images/lidi/davidtlach.jpg" alt="" />
                    <div className="jmeno">David Tlach</div>
                    <p>Trade & Category Manager (FMCG)</p>
                    <p>Big Shock s.r.o.</p>
                </div>
                
                
                

            </div>
        </div>
    </section>
}