import React from 'react';


export function Vysledky2022() {
    return <section className='vysledky'>
        <div className="bezTitulek"></div>
        <div className="container">
            <div className="flexboxVysledky">
                <div className="vysledkyRocnik">2022</div>

            </div>
            <div className="flexboxVysledky">
                <table className='vysledkySpecial'><tr><td>Klient ročníku</td></tr>
                    <tr><td>Komerční banka</td></tr></table>
                <table className='vysledkySpecial'><tr><td>Agentura ročníku</td></tr>
                    <tr><td>McCann Prague</td></tr></table>
            </div>
            <table>
                <tr><td>Název projektu</td><td>Přihlašovatel / spolupracovníci</td><td>Klient</td><td>Kategorie</td><td>Ocenění</td></tr>

                <tr><td>13 minut</td><td>McCann Prague / Hero & Outlaw, Renegadz</td><td>Česká asociace pojišťoven</td><td></td><td><span className='oranzovy bold'>GRAND PRIX</span>
                </td></tr>

                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>To nejlepší ze sousedství</td><td>Momentum Czech Republic</td><td>Státní zemědělský intervenční fond</td><td>Budování značky</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Až vyrostu...</td><td>Blueglue</td><td>SpokojenyPes.cz</td><td>Budování značky</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Síla úsměvu</td><td>Elite solutions</td><td>E.ON</td><td>Budování značky</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>První bezobslužná prodejna Vodafone na pražském letišti</td><td>WELLEN </td><td>Vodafone CZ</td><td>Digitální inovace v retailu</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Bankovní e-gaming láme rekordy</td><td>Momentum Czech Republic / McCann Prague, Good Game, PHD</td><td>Komerční banka MasterCard</td><td>Digitální trefa</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Chio Fried Chicken</td><td>McCann Prague / Good Game</td><td>Intersnack</td><td>Digitální trefa</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Bankovní e-gaming láme rekordy</td><td>Momentum Czech Republic / McCann Prague, Good Game, PHD</td><td>Komerční banka MasterCard</td><td>Event, field a sportovní marketing</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>E.ON Energy Globe 2021</td><td>Elite solutions</td><td>E.ON</td><td>Event, field a sportovní marketing</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Už nikdy fronty na banány</td><td>Flux Films / Dekomunizace, Marek Linhart</td><td>Dekomunizace </td><td>Charitativní a neziskový marketing</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Jak změnit vnímání Komerční banky</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td>Charitativní a neziskový marketing</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Nová Tullamore D.E.W. Honey vyvolala mezi mladými pořádný bzukot</td><td>TRIAD Advertising </td><td>Mast-Jaegermeister CZ </td><td>Influencer marketing</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Když dobře voníš, může se stát cokoliv</td><td>WeDigital</td><td>Unilever</td><td>Influencer marketing</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>13 minut</td><td>McCann Prague / Hero & Outlaw, Renegadz</td><td>Česká asociace pojišťoven </td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Bankovní e-gaming láme rekordy</td><td>Momentum Czech Republic / McCann Prague, Good Game, PHD</td><td>Komerční banka MasterCard</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>To nejlepší ze sousedství</td><td>Momentum Czech Republic</td><td>Státní zemědělský intervenční fond</td><td>Integrovaná komunikace</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Už nikdy fronty na banány</td><td>Flux Films / Dekomunizace, Marek Linhart</td><td>Dekomunizace</td><td>Nízkorozpočtové kampaně</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Snow Report</td><td>McCann Prague</td><td>Mont Gelé Gear </td><td>Nízkorozpočtové kampaně</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Tullamore D.E.W. Honey vymazala 8letý náskok konkurence</td><td>TRIAD Advertising </td><td>Mast-Jaegermeister CZ </td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Chio Fried Chicken</td><td>McCann Prague / Good Game</td><td>Intersnack</td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Jellení přání</td><td>WELLEN </td><td>WELLEN</td><td>Přímá B2B a B2C komunikace</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Automatická distribuce personalizovaných dopisů</td><td>Acomware</td><td>Astratex</td><td>Přímá B2B a B2C komunikace</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Koncept prodejen Super zoo</td><td>MORIS design </td><td>SUPER PET </td><td>Retail koncept</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>První bezobslužná prodejna Vodafone na pražském letišti</td><td>WELLEN </td><td>Vodafone CZ</td><td>Retail koncept</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Pilot pen - Napiš, vymaž, přepiš</td><td>Fragile agency</td><td>Václav Čížek</td><td>Sociální média a&nbsp;brandový obsah</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>13 minut</td><td>McCann Prague / Hero & Outlaw, Renegadz</td><td>Česká asociace pojišťoven </td><td>Sociální média a&nbsp;brandový obsah</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Chio Fried Chicken</td><td>McCann Prague / Good Game</td><td>Intersnack</td><td>Sociální média a&nbsp;brandový obsah</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Bankovní e-gaming láme rekordy</td><td>Momentum Czech Republic / McCann Prague, Good Game, PHD</td><td>Komerční banka MasterCard</td><td>Spotřebitelské soutěže a&nbsp;loajalitní programy</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>ANTIBIOTIKA</td><td>McCann Prague / Remmark, Engage Hill</td><td>Státní zdravotní ústav</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>To nejlepší ze sousedství</td><td>Momentum Czech Republic</td><td>Státní zemědělský intervenční fond</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">bronz</span></td></tr>

            </table>

        </div>


    </section>
}