import React from 'react';
import { Titulek } from '../prvky/Titulek';

export function Pravidla() {
    return <section className="pravidla">
        <div className="proNavigaci" id="pravidla" />
        <Titulek nazev="Pravidla" />
        <div className="containerText">
            <ol>
                <li>
                    Soutěž je otevřená všem reklamním a&nbsp;jiným subjektům, které
                    připravují řešení v&nbsp;oblasti přímé komunikace, digitálních
                    kampaní, podpory prodeje, aktivace značek.
                </li>
                <li>
                    Všechny práce přihlášené do soutěže musí být alespoň částečně
                    realizovány v&nbsp;období od{' '}
                    <span className="bold oranzovy"> 1.&nbsp;1.&nbsp;2023</span> do{' '}
                    <span className="bold oranzovy">31.&nbsp;12.&nbsp;2023</span>, a to
                    výhradně na území České republiky.
                </li>
                <li>
                    Organizátor si vyhrazuje právo nezařadit do soutěže práce, které
                    porušují oborové kodexy nebo působí urážlivě.
                </li>
                <li>
                    Je nepřípustné přihlašovat práce, které soutěžily již dříve
                    v&nbsp;předchozích ročnících soutěže.
                </li>
                <li>
                    Žádná práce nesmí být přihlášena vícenásobně více subjekty.
                    V&nbsp;takovém případě bude akceptována časově první přihláška.
                </li>
                <li>
                    Přihlašovatel si je vědom, že přihlášením své práce neporušuje žádná
                    autorská práva a&nbsp;potvrzuje, že s&nbsp;přihlášením práce souhlasí
                    i&nbsp;její zadavatel.
                </li>
                <li>
                    Všechny přihlášené práce musí vzniknout na základě objednávky
                    a&nbsp;následné platby zadavatelem. Jedinými výjimkami jsou
                    self-promotion a&nbsp;práce pro neziskové organizace. Zadavatel musí
                    zaplatit, když ne všechny, tak alespoň většinu produkčních
                    a&nbsp;mediálních nákladů aktivity.
                </li>
                <li>
                    Organizátor si vyhrazuje právo vznést na uvedeného zadavatele případné
                    dotazy položené hodnotící porotou.
                </li>
                <li>
                    Organizátor si vyhrazuje právo převést práce do jiné kategorie, pokud
                    neodpovídají původně přihlášeným. Musí se tak ale stát s&nbsp;vědomím
                    přihlašovatele a&nbsp;před hlasováním poroty.
                </li>
                <li>
                    Všechny práce musí být přihlášeny elektronicky na{' '}
                    <a className="oranzovy" href="www.impactczechawards.cz">
                        www.impactczechawards.cz
                    </a>. Součástí přihlášky je uvedení kontaktní osoby a&nbsp;kontaktu na
                    straně klienta pro případné potvrzení publikovaných výsledků.
                </li>
                <li>
                    Všechny práce musí být přihlášeny v&nbsp;podobě, v&nbsp;jaké byly
                    publikovány či v&nbsp;jaké proběhly bez jakýchkoliv dalších úprav
                    (s&nbsp;výjimkou překladů). Přihlašovacím jazykem pro internetovou
                    přihlášku je čeština.
                </li>
                <li>
                    Organizátor si vyhrazuje právo požadovat po přihlašovateli plné
                    informace o&nbsp;nasazení a&nbsp;provedení kampaně v&nbsp;případě, že
                    postoupí na shortlist.
                </li>
                <li>
                    Přihlášením soutěžní práce dává přihlašovatel organizátorovi soutěže
                    právo použít dodanou dokumentaci k&nbsp;publicitě a&nbsp;k&nbsp;jiným
                    účelům a&nbsp;zříká se odměny za užití díla.
                </li>
                <li>
                    V&nbsp;případě podezření z&nbsp;nedodržení pravidel se organizátor
                    zavazuje provést došetření, které může vést až k&nbsp;odebrání ceny či
                    umístění na shortlistu.
                </li>
            </ol>
        </div>
        <div className="proNavigaci" id="postup" />
        <div className="hlavicka" id="hlavickaPostup">
            <div className="carkovany">
                <div className="levyhorni ctverec" />
                <div className="levydolni ctverec" />
                <div className="horni ctverec" />
                <div className="dolni ctverec" />
                <div className="pravyhorni ctverec" />
                <div className="pravydolni ctverec" />
                <h2>Postup</h2>
            </div>
        </div>
        <div className="containerText">
            <ol>
                <li>
                    Do všech kategorií se otevírá přihlašovací internetový formulář dne{' '}
                    <span className="bold oranzovy">22.&nbsp;dubna&nbsp;2024</span>.
                    Posledním dnem, kdy je možné se přihlásit a&nbsp;dokdy musí být
                    internetová přihláška dokončena a&nbsp;úhrada přihlašovacího poplatku
                    provedena, je{' '}
                    <span className="bold oranzovy">8.&nbsp;července&nbsp;2024.</span>
                </li>
                <li>
                    Jednu kampaň/aktivitu je možné přihlásit do více kategorií. Do každé
                    musí být přihlášena samostatně.
                </li>
                <li>
                    Jako první krok přihlášení je třeba zaregistrovat přihlašovatele na
                    webu{' '}
                    <a className="oranzovy" href="www.impactczechawards.cz">
                        www.impactczechawards.cz
                    </a>.
                    Po tomto zaregistrování a&nbsp;jeho potvrzení ze strany organizátora
                    je teprve možné přihlašovat elektronicky i&nbsp;jednotlivé práce,
                    a&nbsp;to vždy každou samostatně.
                </li>
                <li>
                    Elektronické přihlášky se skládají z&nbsp;těchto částí, u&nbsp;kterých
                    nelze překročit počet slov
                    <table>
                        <tbody>
                            <tr>
                                <td>CÍLE A&nbsp;POZADÍ AKTIVITY</td>
                                <td>max. 200 slov</td>
                            </tr>
                            <tr>
                                <td>POPIS JAK SE AKTIVITA VYVINULA OD KONCEPTU PO REALIZACI</td>
                                <td>max. 200 slov</td>
                            </tr>
                            <tr>
                                <td>
                                    VYSVĚTLENÍ, PROČ ZVOLENÁ AKTIVITA JE PRO PRODUKT NEBO SLUŽBU
                                    NEJVHODNĚJŠÍ
                                </td>
                                <td>max. 200 slov</td>
                            </tr>
                            <tr>
                                <td>
                                    POPIS ÚSPĚCHŮ AKTIVITY Z POHLEDU ZADAVATELE I SPOTŘEBITELŮ
                                    VČETNĚ KVANTIFIKOVANÝCH VÝSLEDKŮ
                                </td>
                                <td>max. 200 slov</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    Elektronický board i&nbsp;další doprovodné materiály jsou pro potřeby
                    poroty a&nbsp;musí být anonymní!
                </li>
                <li>
                    Povinnou přílohou on-line přihlášky je elektronický prezentační board
                    (tedy image obrázek kampaně) s delší stranou 950px ve formátu JPEG,
                    s&nbsp;objemem dat max. 1 MB.
                </li>
                <li>
                    Dále je možné doplnit:
                    <br />
                    <span className="bold">1× video</span> ve formátu vhodném pro YouTube
                    v&nbsp;maximální délce trvání 2 minuty, a&nbsp;to buď formou odkazu,
                    nebo zasláním přes{' '}
                    <a className="oranzovy"
                        href="http://www.uschovna.cz"
                        target="_blank"
                        rel="noreferrer">
                        www.uschovna.cz
                    </a>{' '}
                    nebo jiný podobný server na adresu{' '}
                    <a className="oranzovy" href="mailto:info@impactczechawards.cz">
                        info@impactczechawards.cz
                    </a>
                    .<br />
                    V&nbsp;takovém případě do kolonky Odkaz na video napište informaci:
                    Posláno přes úschovnu.
                    <br />
                    <br />
                    <span className="bold">1× rozhlasový spot</span> ve formátu MP3
                    (možnost jej připojit se součástí online přihlášky).
                    <br />
                    <br />
                    <span className="bold">URL</span> pro digitální materiály.
                </li>
            </ol>
        </div>
    </section>
}