import React from 'react';

export function Titulek({nazev}: { nazev: string }) {
    return <div className="hlavicka">
        <div className="carkovany">
            <div className="levyhorni ctverec"/>
            <div className="levydolni ctverec"/>
            <div className="horni ctverec"/>
            <div className="dolni ctverec"/>
            <div className="pravyhorni ctverec"/>
            <div className="pravydolni ctverec"/>
            <h2>{nazev}</h2>
        </div>
    </div>
}