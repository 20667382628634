import React from 'react';


export function Vysledky2021() {
    return <section className='vysledky'>
        <div className="bezTitulek"></div>
        <div className="container">
            <div className="flexboxVysledky">
                <div className="vysledkyRocnik">2021</div>

            </div>
            <div className="flexboxVysledky">
                <table className='vysledkySpecial'><tr><td>Klient ročníku</td></tr>
                    <tr><td>Mastercard</td></tr></table>
                <table className='vysledkySpecial'><tr><td>Agentura ročníku</td></tr>
                    <tr><td>McCann Prague</td></tr></table>
            </div>
            <table>
                <tr><td>Název projektu</td><td>Přihlašovatel / spolupracovníci</td><td>Klient</td><td>Kategorie</td><td>Ocenění</td></tr>

                <tr><td>Kotel a Výbuch</td><td>Momentum Czech Republic</td><td>Bohemia Energy</td><td>Budování značky</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Proud</td><td>WeDigital</td><td>Plzeňský Prazdroj</td><td>Budování značky</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Sliby mojí vagíně</td><td>SYMBIO Digital</td><td>HARTMANN-RICO</td><td>Budování značky</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Gambrinus Výkopná 2020</td><td>TRIAD Advertising CZ</td><td>Asahi Breweries</td><td>Digitální trefa</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Launch Chio Popcorn: From Zero to Movie Hero</td><td>McCann Prague</td><td>Intersnack</td><td>Digitální trefa</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Hackathon #znamkamarada</td><td>WMC Praha / ACTUM Digital</td><td>Známkamaráda</td><td>Charitativní a neziskový marketing</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>#hýbemesehezkyčesky</td><td>Hullabaloo / IS Produkce </td><td>PENNY Market </td><td>Charitativní a neziskový marketing</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Mastercard: Získat srdce hráčů? K nezaplacení.</td><td>McCann Prague / Good Game</td><td>Mastercard</td><td>Influencer marketing</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Hody bez kravin</td><td>WeDigital</td><td>Unilever</td><td>Influencer marketing</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Lego vzalo herní svět šmahem</td><td>McCann Prague / Good Game</td><td>Lego</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Personalizace funguje!</td><td>Wunderman Thompson s.r.o. / Motionlab, SmartEmailing</td><td>innogy Energie </td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Gambrinus Výkopná 2020</td><td>TRIAD Advertising CZ</td><td>Asahi Breweries</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>Kdo zničil reklamu HBO?</td><td>SYMBIO Digital, s.r.o.</td><td>HBO Europe</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Lego vzalo herní svět šmahem</td><td>McCann Prague / Good Game</td><td>Lego</td><td>Nízkorozpočtové kampaně</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Fosílie, s.r.o.</td><td>Elite solutions</td><td>E.ON</td><td>Nízkorozpočtové kampaně</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Radegast RATAR – hořké pivo plné hořkých výzev</td><td>Boomerang Communication / Plzeňský Prazdroj (značka RADEGAST)</td><td>Plzeňský Prazdroj</td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Kdo zničil reklamu HBO?</td><td>SYMBIO Digital</td><td>HBO Europe</td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>Launch Chio Popcorn: From Zero to Movie Hero</td><td>McCann Prague</td><td>Intersnack</td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Personalizace funguje!</td><td>Wunderman Thompson / Motionlab, SmartEmailing</td><td>innogy Energie </td><td>Přímá B2B a B2C komunikace</td><td><span className="oranzovy bold">zlato</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Tokinomo - Robotická ruka</td><td>KNOWINSTORE / ONEPlanet, ONEBusiness </td><td>L'Oréal Česká republika </td><td>Retail/merchandising/in-store marketing ...</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Sliby mojí vagíně</td><td>SYMBIO Digital</td><td>HARTMANN-RICO</td><td>Sociální média a&nbsp;brandový obsah</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Fosílie, s.r.o.</td><td>Elite solutions</td><td>E.ON</td><td>Sociální média a&nbsp;brandový obsah</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Kinder pohádka</td><td>Momentum Czech Republic</td><td>Ferrero Česká</td><td>Sociální média a&nbsp;brandový obsah</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>LISTERINE, Najdi svůj typ</td><td>Fragile agency / Universal McCann (media buying kampaně)</td><td>Johnson & Johnson</td><td>Sociální média a brandový obsah</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Mastercard: Získat srdce hráčů? K nezaplacení.</td><td>McCann Prague / Good Game</td><td>Mastercard</td><td>Sponzoring</td><td><span className="oranzovy bold">zlato</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Kinder pohádka</td><td>Momentum Czech Republic</td><td>Ferrero Česká</td><td>Spotřebitelské soutěže a&nbsp;loajalitní programy</td><td><span className="oranzovy bold">zlato</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>100 000 projektů pro bezpečné Česko</td><td>Momentum Czech Republic</td><td>Ministerstvo pro místní rozvoj</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Brejlando</td><td>Brainz Disruptive</td><td>Magistrát hl. m. Prahy</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Jak #MUNICHALLENGE přivedla Masarykovu univerzitu od sedmiletého propadu k nárůstu přihlášek o 12 %</td><td>Media age</td><td>Masarykova univerzita</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">bronz</span></td></tr>

            </table>

        </div>


    </section>
}